<template>
    <v-sheet color="primary lighten-1" :height="'calc(100vh - ' + $vuetify.application.top + 'px)'">
        <v-container fluid class="pt-12">
            <v-row align-content="center" justify="center">
                <v-col cols="12" sm="8" md="4">
                <v-card class="pb-4">
                    <v-toolbar dark flat color="primary">
                        <v-toolbar-title>{{'Sign In' | localize }}</v-toolbar-title>
                        <v-spacer></v-spacer>
                        <v-tooltip bottom>
                            <template v-slot:activator="{ on }">
                                <v-btn icon color="white"
                                v-on="on"
                                to="/">
                                <v-icon>mdi-close-circle</v-icon>
                                </v-btn>
                            </template>
                            <span>{{ 'close' | localize }}</span>
                        </v-tooltip>
                    </v-toolbar>

                    <v-card-text>
                        <v-form v-model="valid" ref="form" validation>
                            <v-text-field color="primary" 
                                :label="'Email' | localize" 
                                name="email" 
                                prepend-icon="mdi-account" 
                                type="text"
                                v-model="email" 
                                :rules="[rules.email, rules.required]">
                            </v-text-field>
                            <v-text-field color="primary" 
                                :label="'Password' | localize" 
                                name="password" 
                                prepend-icon="mdi-lock"
                                :hint="'min 6 characters' | localize"
                                minlength="6"
                                :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
                                :type="show ? 'text' : 'password'"
                                v-model="password"
                                :rules="[rules.required, rules.min]"
                                @click:append="show = !show">
                            </v-text-field>
                        </v-form>
                    </v-card-text>

                    <v-card-actions class="mt-4">
                        <v-spacer></v-spacer>
                        <v-btn small outlined 
                            class="text-capitalize mb-4 mr-4" 
                            color="primary" 
                            @click="onSubmit" 
                            :loading="loading"
                            :disabled="!valid || loading">
                            {{'confirm' | localize }}
                        </v-btn>
                    </v-card-actions>
                    <v-divider></v-divider>
                    <div class="text-left my-2 ml-2">
                        <span class="caption ml-2">
                            {{'No account yet' | localize }}?
                        </span>
                        <v-btn small text class="text-capitalize" color="primary" to="/signup" :loading="loading">
                            {{'Sign Up' | localize }}
                        </v-btn>
                    </div>
                    <div class="text-left my-2 ml-2">
                        <span class="caption ml-2">
                            {{ 'Lost your password' | localize }} ?
                        </span>
                        <v-btn small text class="text-lowercase" color="primary" to="/resetPass" :loading="loading">
                            {{ 'Reset password' | localize }}
                        </v-btn>
                    </div>
                </v-card>
                </v-col>
            </v-row>
        </v-container>
    </v-sheet>
</template>
<script>
import localizeFilter from '../filters/localize.filter'

export default {
    data() {
        return {
            valid: false,
            email: '',
            password: '',
            show: false,
            rules: {
                required: value => !!value || localizeFilter('required field'),
                min: v => v.length >= 6 || localizeFilter('min 6 characters'),
                email: value => {
                    const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                    return pattern.test(value) || localizeFilter('invalid e-mail')
                }
            }
        }
    },
    computed: {
        loading() {
            return this.$store.getters.loading;
        },
        user() {
            return this.$store.getters.user;
        },
        userData() {
            return this.$store.getters.userData;
        }     
    },
    methods: {
        async onSubmit() {
            const user = {}
            
            if (this.$refs.form.validate()) {
                user.email = this.email
                user.password = this.password

                try {
                    await this.$store.dispatch('login', user)

                    if (this.user!== null) {
                        this.dialog = false;
                        this.valid = false;
                        this.email = '';
                        this.password = '';
                
                        if (this.userData.role == 'USER') {
                            this.$router.replace('/profile');
                        }
                        if (this.userData.role == 'ADMIN') {
                            this.$router.replace('/dashboard');
                        }
                    }
                }
                catch (error) {
                    await this.$store.dispatch('setError', error.code);
                }
            }
        }
    },
}
</script>